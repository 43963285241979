import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandlerFn,
    HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { Observable } from 'rxjs';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    const authService = inject(AuthService);

    const allowedRoutes: string[] = [
        'https://geoserver.geoqi.com.br/geoserver',
    ];

    if (allowedRoutes.some((route) => req.url.includes(route))) {
        // Return an empty Observable (you might want to just return `next(req)` instead)
        return next(req);
    } else {
        return new Observable<HttpEvent<any>>((observer) => {
            authService.accessToken.subscribe((token) => {
                const newReq = req.clone({
                    headers: req.headers.set(
                        'Authorization',
                        'Bearer ' + token
                    ),
                });
                next(newReq).subscribe(
                    (event) => observer.next(event),
                    (error) => {
                        if (
                            error instanceof HttpErrorResponse &&
                            error.status === 401
                        ) {
                            authService.signOut();
                        }
                        observer.error(error);
                    },
                    () => observer.complete()
                );
            });
        });
    }
};
