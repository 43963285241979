import { Route } from '@angular/router';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { initialDataResolver } from './app.resolvers';
import { AuthGuard } from './core/auth/guards/auth.guard';

export const appRoutes: Route[] = [
    //Home
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        resolve: {
            initialData: initialDataResolver,
        },
        component: LayoutComponent,
    },

    // Admin routes
    {
        path: 'administrador',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver,
        },
        data: {
            layout: 'classy',
            role: 'ADMIN',
        },
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('app/modules/admin/admin.module').then(
                        (m) => m.AdminModule
                    ),
            },
        ],
    },

    // User routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'classy',
            role: 'USER',
        },
        resolve: {
            initialData: initialDataResolver,
        },
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('app/modules/user/user.module').then(
                        (m) => m.UserModule
                    ),
                data: {
                    layout: 'classy',
                    role: 'USER',
                },
            },
        ],
    },

    // Auth routes
    {
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        path: 'autorizacao',
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('app/modules/auth/auth.module').then(
                        (m) => m.AuthModule
                    ),
            },
        ],
    },

    //Rotas não existentes
    { path: '**', redirectTo: '/autorizacao/entrar' },
];
