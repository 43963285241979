import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Observable, firstValueFrom } from 'rxjs';
import { AuthService } from '../auth.service';
@Injectable({
    providedIn: 'root',
})
export class NoAuthGuard {
    constructor(
        private router: Router,
        private authService: AuthService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        const url: string = state.url;
        return this.checkUser(route, url);
    }
    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        const url: string = state.url;
        return this.checkUser(childRoute, url);
    }

    async checkUser(route: ActivatedRouteSnapshot, url: any): Promise<boolean> {
        const isAuthenticated = await firstValueFrom(
            this.authService.isLoggedIn()
        );

        if (isAuthenticated) {
            this.router.navigate(['']);
            // this.router.navigate(['/mapa-cidade']);
            return false;
        } else {
            return true;
        }
    }
}
