import { inject, Injectable } from '@angular/core';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationMockApi } from 'app/mock-api/common/navigation/api';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NavigationService {
    // private _httpClient = inject(HttpClient);
    private _navigationMockApi = inject(NavigationMockApi);
    private _navigation: ReplaySubject<Navigation> =
        new ReplaySubject<Navigation>(1);

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    // get(): Observable<Navigation> {
    //     return this._httpClient.get<Navigation>('api/common/navigation').pipe(
    //         tap((navigation) => {
    //             this._navigation.next(navigation);
    //         })
    //     );
    // }

    get(): Observable<Navigation> {
        return new Observable<Navigation>((observer) => {
            this._navigationMockApi.navigation$.subscribe({
                next: (navigation) => {
                    this._navigation.next(navigation);
                    observer.next(navigation);
                    observer.complete();
                },
                error: (error) => {
                    observer.error(error);
                },
            });
        });
    }
}
