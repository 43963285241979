import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { AppComponent } from 'app/app.component';
import { environment } from 'environments/environment';
import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { appConfig } from './app.config';
import { appRoutes } from './app.routes';
import { provideAuthInterceptor } from './core/auth/auth.provider';
import { customMatPaginator } from './helpers/custom-mat-paginator';

registerLocaleData(ptBr);
const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    //enableTracing: true,
};

const maskConfig: Partial<IConfig> = null;

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
    ],

    providers: [
        ...appConfig.providers,
        //Interceptador
        provideAuthInterceptor(),
        //Mudando campos do componente de paginação em portugues
        {
            provide: MatPaginatorIntl,
            useValue: customMatPaginator(),
        },
        { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
        { provide: LOCALE_ID, useValue: 'pt' },

        //Mask
        provideEnvironmentNgxMask(maskConfig),

        //Firebase Authentication
        provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
        provideAuth(() => getAuth()),
    ],

    bootstrap: [AppComponent],
})
export class AppModule {}
