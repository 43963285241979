import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import {
    EnumTranslateStatusService,
    ServiceRequest,
    StatusColors,
    StatusService,
} from 'app/models/serviceRequest';
import { ServiceRequestType } from 'app/models/serviceType';
import { ServiceRequestService } from 'app/services/service-requests/serviceRequests.service';
import { ServiceTypesService } from 'app/services/service-requests/serviceTypes.service';
import { ApexOptions, ChartComponent, NgApexchartsModule } from 'ng-apexcharts';
import { BehaviorSubject, debounceTime, filter } from 'rxjs';

@Component({
    selector: 'app-home-admin',
    standalone: true,
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
        MatPaginatorModule,
        MatTableModule,
        MatButtonModule,
        MatIconModule,
        RouterModule,
        NgApexchartsModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatMomentDateModule,
    ],
    templateUrl: './home-admin.component.html',
    styleUrl: './home-admin.component.scss',
})
export class HomeAdminComponent implements OnInit {
    @ViewChild('chart-polar') chart: ChartComponent;
    public chartOptions: Partial<ApexOptions>;

    form: FormGroup;
    types: ServiceRequestType[];
    status = Object.entries(EnumTranslateStatusService);
    statusCounts: Record<StatusService, number>;
    StatusColors = StatusColors;
    services: ServiceRequest[];
    displayedColumns: string[] = [
        'description',
        'user_request',
        'user_rt',
        'actions',
    ];
    // Crie um BehaviorSubject para armazenar o último campo alterado
    private lastChangedField = new BehaviorSubject<string | null>(null);

    //Paginação
    currentPage = 0;
    length = 0;
    pageSizeOptions: number[] = [5, 10, 20];
    pageSize: number = this.pageSizeOptions[1];
    pageEvent: PageEvent;
    pageIndex: number = 0;

    // Getter para somar os valores
    get totalServices(): number {
        return Object.values(this.statusCounts).reduce(
            (sum, count) => sum + count,
            0
        );
    }

    constructor(
        private _serviceTypesService: ServiceTypesService,
        private _serviceRequestService: ServiceRequestService
    ) {}

    ngOnInit(): void {
        this.form = new FormGroup({
            description: new FormControl(null),
            emailUserRequest: new FormControl(null),
            serviceType: new FormControl(null),
            status: new FormControl(null),
            optionDate: new FormControl(null),
            date_start: new FormControl(null),
            date_end: new FormControl(null),
        });

        // Detecta mudanças nos campos e atualiza o BehaviorSubject
        Object.keys(this.form.controls).forEach((field) => {
            this.form.get(field)?.valueChanges.subscribe(() => {
                this.lastChangedField.next(field); // Atualiza o último campo alterado
            });
        });

        this.form.valueChanges
            .pipe(
                debounceTime(1000),
                filter(() => {
                    const dirtyFields = [
                        'optionDate',
                        'date_start',
                        'date_end',
                    ];

                    // Obtém o último campo alterado
                    const lastField = this.lastChangedField.getValue();

                    // Executa apenas se o último campo alterado não for um dos indesejados
                    return !dirtyFields.includes(lastField || '');
                })
            )
            .subscribe(() => {
                this.getServices(true);
            });

        this.getTypes();
        this.getServices();
    }

    getTypes(): void {
        this._serviceTypesService.getTypes().subscribe((res) => {
            this.types = res;
        });
    }

    getServices(updateFilter?: boolean): void {
        //Se o filtro mudou, volta para primeira pagina
        if (updateFilter) {
            this.pageIndex = 0;
            this.currentPage = 0;
        }

        const formValue = this.form.value;

        this._serviceRequestService
            .getServicesWithStatusAndCount(
                this.pageSize,
                this.currentPage,
                formValue.description,
                formValue.emailUserRequest,
                formValue.serviceType,
                formValue.status,
                formValue.date_start,
                formValue.date_end
            )
            .subscribe((res) => {
                this.services = res.services;
                this.length = res.total;
                this.statusCounts = res.statusCounts;
                this.configChart();
            });
    }

    configChart() {
        this.chartOptions = {
            series: Object.values(this.statusCounts),
            chart: {
                type: 'polarArea',
                events: {
                    dataPointSelection: (event, chartContext, config) => {
                        const selectedIndex = config.dataPointIndex; // Índice do segmento clicado
                        const selectedLabel =
                            this.chartOptions.labels[selectedIndex]; // Label correspondente

                        const status = this.getStatusByLabel(selectedLabel);

                        this.setStatus(status);
                    },
                },
            },
            labels: Object.keys(this.statusCounts).map(
                (key) => EnumTranslateStatusService[key as StatusService]
            ),
            fill: {
                opacity: 0.5,
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200,
                        },
                        legend: {
                            position: 'bottom',
                        },
                    },
                },
            ],
        };
    }

    //Evento de paginação
    onPageChange(event?: PageEvent): PageEvent {
        if (event !== undefined) {
            this.pageIndex = event.pageIndex;
            this.pageSize = +event.pageSize;
            this.currentPage = event.pageSize * event.pageIndex;
            this.getServices();
        }
        return event;
    }

    getStatusByLabel(label: string): StatusService | undefined {
        return Object.keys(EnumTranslateStatusService).find(
            (key) =>
                EnumTranslateStatusService[
                    key as keyof typeof EnumTranslateStatusService
                ] === label
        ) as StatusService | undefined;
    }

    setStatus(status: StatusService | null) {
        if (this.form.value?.status != status)
            this.form.patchValue({ status: status });
    }

    onSelectionChange($event: MatSelectChange) {
        const dateToday = new Date();
        dateToday.setHours(0, 0, 0, 0); // Início do dia atual

        switch ($event.value) {
            case 'today': {
                const endToday = new Date(dateToday);
                endToday.setHours(23, 59, 59, 999); // Fim do dia atual

                this.form.patchValue({
                    date_start: dateToday.toISOString(),
                    date_end: endToday.toISOString(),
                });
                break;
            }
            case 'yesterday': {
                const dateYesterday = new Date(dateToday);
                dateYesterday.setDate(dateToday.getDate() - 1); // Início de ontem
                const endYesterday = new Date(dateYesterday);
                endYesterday.setHours(23, 59, 59, 999); // Fim de ontem

                this.form.patchValue({
                    date_start: dateYesterday.toISOString(),
                    date_end: endYesterday.toISOString(),
                });
                break;
            }
            case 'last7days': {
                const dateLast7days = new Date(dateToday);
                dateLast7days.setDate(dateToday.getDate() - 7); // Início dos últimos 7 dias
                const endToday = new Date(dateToday);
                endToday.setHours(23, 59, 59, 999); // Fim do dia atual

                this.form.patchValue({
                    date_start: dateLast7days.toISOString(),
                    date_end: endToday.toISOString(),
                });
                break;
            }
            case 'last15days': {
                const dateLast15days = new Date(dateToday);
                dateLast15days.setDate(dateToday.getDate() - 15); // Início dos últimos 15 dias
                const endToday = new Date(dateToday);
                endToday.setHours(23, 59, 59, 999); // Fim do dia atual

                this.form.patchValue({
                    date_start: dateLast15days.toISOString(),
                    date_end: endToday.toISOString(),
                });
                break;
            }
            case 'lastMonth': {
                const dateLast30days = new Date(dateToday);
                dateLast30days.setDate(dateToday.getDate() - 30); // Início dos últimos 30 dias
                const endToday = new Date(dateToday);
                endToday.setHours(23, 59, 59, 999); // Fim do dia atual

                this.form.patchValue({
                    date_start: dateLast30days.toISOString(),
                    date_end: endToday.toISOString(),
                });
                break;
            }
            default:
                this.form.patchValue({
                    date_start: null,
                    date_end: null,
                });
        }
        this.getServices(true);
    }

    areDatesValid(): boolean {
        const optionDate = this.form.get('optionDate')?.value;
        const dateStart = this.form.get('date_start')?.value;
        const dateEnd = this.form.get('date_end')?.value;

        if (optionDate === 'specific') {
            return !!dateStart; // A data específica deve estar preenchida
        }

        if (optionDate === 'interval') {
            // Ambas as datas devem estar preenchidas e dateStart <= dateEnd
            return (
                !!dateStart &&
                !!dateEnd &&
                new Date(dateStart) <= new Date(dateEnd)
            );
        }

        return true; // Habilita o botão para outras opções
    }

    filterDate() {
        const optionDate = this.form.get('optionDate')?.value;
        const dateStart = this.form.get('date_start')?.value;
        const dateEnd = this.form.get('date_end')?.value;

        if (optionDate === 'specific') {
            const endToday = new Date(dateStart);
            endToday.setHours(23, 59, 59, 999); // Fim do dia atual
            this.form.patchValue({
                date_start: dateStart.toISOString(),
                date_end: endToday.toISOString(),
            });
        }
        this.getServices(true);
    }
}
